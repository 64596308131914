import { Controller } from '@hotwired/stimulus'

// HEXIS FRONTEND
import $console from '@hexis-hr/utils/console'


// No lazy load for this.
export default class extends Controller<HTMLElement> {
  static targets = ['themeChoice', 'fontChoice', 'sizeChoice']

  // Local state
  theme: null | 'light' | 'dark'
  font: null | string
  size: null | string


  /* --------------------------------------------------------------------------
    CONTROLLER LIFECYCLE HOOKS
    Try to keep this short and clean it should just "tell a story".
    Most of the code should be written in meaningfully named methods below.
  -------------------------------------------------------------------------- */

  // (1) Once, when the controller is first instantiated
  initialize() { }

  // (2) Anytime the controller is connected to the DOM
  connect() {
    this.loadPreferences()
  }

  // (3) Anytime the controller is disconnected from the DOM
  disconnect() { }


  /* --------------------------------------------------------------------------
    PREFERENCE STORAGE
  -------------------------------------------------------------------------- */
  savePreferences(): void {
    localStorage.setItem('accessibility', JSON.stringify({
      theme: this.theme,
      font: this.font,
      size: this.size
    }))
  }

  loadPreferences(): void {
    let a11y = localStorage.getItem('accessibility')
    a11y = a11y ? JSON.parse(a11y) : {}
    $console.log('Loading accessibility preferences:', a11y)

    if (a11y?.theme) this.setTheme(a11y.theme, true)
    if (a11y?.font) this.setFontFamily(a11y.font, true)
    if (a11y?.size) this.setFontSize(a11y.size, true)
  }

  resetAll(): void {
    this.setTheme(null, true)
    this.setFontFamily(null, true)
    this.setFontSize(null, true)
  }


  /* --------------------------------------------------------------------------
    THEME SWITCHING
  -------------------------------------------------------------------------- */
  onThemeClick(event: Event & { params: { theme: typeof this.theme } }): void {
    this.setTheme(event.params.theme)
  }

  setTheme(
    value?: typeof this.theme,
    forceDomUpdate?: boolean,
  ): void {
    this.theme = value || 'light'
    this.savePreferences()
    document.documentElement.dataset.theme = this.theme

    // Iterates all radio inputs and marks the active one
    if (forceDomUpdate) {
      const normalizedValue = this.theme === null ? '' : this.theme
      const inputs = [...this.themeChoiceTarget.querySelectorAll(`input`)]
      inputs.forEach(input => {
        input.checked = input.value === normalizedValue
      })
    }
  }


  /* --------------------------------------------------------------------------
    FONT SWITCHING
  -------------------------------------------------------------------------- */
  onFontFamilyClick(event: Event & { params: { font: typeof this.font } }): void {
    this.setFontFamily(event.params.font)
  }

  setFontFamily(
    value?: typeof this.font,
    forceDomUpdate?: boolean,
  ): void {
    if (value) {
      this.font = value
      this.savePreferences()
      document.documentElement.style.setProperty('--default-font-family', this.font)
    } else {
      this.font = null
      this.savePreferences()
      document.documentElement.style.removeProperty('--default-font-family')
    }

    // Iterates all radio inputs and marks the active one
    if (forceDomUpdate) {
      const normalizedValue = this.font === null ? '' : this.font
      const inputs = [...this.fontChoiceTarget.querySelectorAll(`input`)]
      inputs.forEach(input => {
        input.checked = input.value === normalizedValue
      })
    }
  }


  /* --------------------------------------------------------------------------
    FONT SIZE SCALING
  -------------------------------------------------------------------------- */
  onFontSizeClick(event: Event & { params: { size: typeof this.size } }): void {
    this.setFontSize(event.params.size)
  }

  setFontSize(
    value: typeof this.size,
    forceDomUpdate?: boolean,
  ): void {
    if (value) {
      this.size = value
      this.savePreferences()
      document.documentElement.style.setProperty('font-size', this.size)
    } else {
      this.size = null
      this.savePreferences()
      document.documentElement.style.removeProperty('font-size')
    }

    // Iterates all radio inputs and marks the active one
    if (forceDomUpdate) {
      const normalizedValue = this.size === null ? '' : this.size
      const inputs = [...this.sizeChoiceTarget.querySelectorAll(`input`)]
      inputs.forEach(input => {
        input.checked = input.value === normalizedValue
      })
    }
  }


}
